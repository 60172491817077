.page-header {
   padding: $grid-gutter-width*2 0;
   h1 {
    margin: 0;
    text-align: center;
   }
   time {
     @extend .eurostile;
     font-weight: $font-weight-med;
     display: block;
     margin: $grid-gutter-width/2 0 0;
     text-align: center;
   }
}
.hero {
  position: relative;
  background-color: $gray22;
  img {
    opacity: .66;
    width: 100%;
  }
  .page-header {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
  }
}
