body.listen{
  .mejs-container {
    background-color: $black;
    height: 60px !important;
    .mejs-controls {
      height: 60px !important;
      .mejs-button {
        width: 50px;
        height: 50px;
        margin-top: 5px;
        button {
          margin: 0;
          width: 50px;
          height: 50px;
          background: none;
          color: $white;
          &:before {
            font-family: "Font Awesome 5 Free";
            font-weight: 400;
            content: "\f144";
            font-size: 50px;
            line-height: 1;
            display: none;
          }
          &:after {
            font-family: "Font Awesome 5 Free";
            font-weight: 400;
            content: "\f28b";
            font-size: 50px;
            line-height: 1;
            display: none;
          }
          &:hover {
            color: $brand-primary;
          }
        }
        &.mejs-play,&.mejs-replay {
          button:before {
            display: block;
          }
        }
        &.mejs-pause {
          button:after {
            display: block;
          }
        }
      }
      .mejs-time,.mejs-volume-button,.mejs-horizontal-volume-slider {
        display: none;
      }
      .mejs-time-rail {
        margin-left: 20px;
        padding-top: 20px;
        .mejs-time-total {
          background-color: rgba($gray3f,.75) !important;
//           border-radius: 20px;
        }
        .mejs-time-loaded {
          background-color: $gray3f;
        }
        .mejs-time-current {
          background-color: $brand-primary;
//           border-radius: 20px;
        }
        .mejs-time-float {
//           background-color: $brand-secondary;
        }
        .mejs-time-hovered {
          background-color: rgba($white,.1);
          &.negative {
            background-color: rgba($black,.2);
          }
        }
        .mejs-time-handle-content {
          border-color: $brand-secondary;
          background-color: $brand-secondary;
          border-radius: 1px;
        }
      }

    }
  }
}
