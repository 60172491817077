.tippy-tooltip {
  &.tank-theme {
    box-shadow: 1px 1px 12px 1px rgba($black-true,0.15);
    background-color: $brand-primary;
    font-size: $font-size-xs;
    -webkit-font-smoothing: inherit !important;
    border-radius: 6px;
    padding: 12px 0;
    width: 300px !important;
    @include media-breakpoint-up(md) {
      width: 340px !important;
    }
    @include media-breakpoint-up(lg) {
      width: 380px !important;
    }
    .tippy-roundarrow {
      fill: $brand-primary;
    }
    .tippy-content {
      min-height: 100px;
      margin: 0;
      background-color: $popover-bg;
      text-align: left;
      img {
      }
      p {
        padding: 10px 20px;
        padding-right: 20px;
        margin: 0;
        & + p {
          padding-top: 0;
        }
      }
      a {
        &:hover {
          color: $link-color;
        }
      }
    }
  }
}
