@supports
  ((-webkit-text-stroke-color: $white)
  and
  (-webkit-text-fill-color: transparent))
  or
  ((-moz-text-stroke-color: $white)
  and
  (-moz-text-fill-color: transparent)) {
  .outlined {
       -moz-text-fill-color: transparent;
    -webkit-text-fill-color: transparent;
       -moz-text-stroke-color: $white;
    -webkit-text-stroke-color: $white;
       -moz-text-stroke-width: $border-width;
    -webkit-text-stroke-width: $border-width;
  }
  .outlined-hover {
       -moz-text-stroke-color: $gray;
    -webkit-text-stroke-color: $gray;
  }
  .outlined-fill {
       -moz-text-fill-color: $white;
    -webkit-text-fill-color: $white;
       -moz-text-stroke-color: $white;
    -webkit-text-stroke-color: $white;
       -moz-text-stroke-width: 0;
    -webkit-text-stroke-width: 0;
  }
}
.eurostile {
  text-transform: uppercase;
  letter-spacing: .1em;
  font-family: $font-family-serif;
  font-weight: $font-weight-bold;
}
.block-padding {
  padding-top: $grid-gutter-width*2;
  padding-bottom: $grid-gutter-width*2;
  @include media-breakpoint-up(md) {
    padding-top: $grid-gutter-width*3;
    padding-bottom: $grid-gutter-width*3;
  }
}
.block-padding-md {
  padding-top: $grid-gutter-width*1.5;
  padding-bottom: $grid-gutter-width*1.5;
  @include media-breakpoint-up(md) {
    padding-top: $grid-gutter-width*2;
    padding-bottom: $grid-gutter-width*2;
  }
}
.grid {
  font-size: $font-size-xs;
  margin-bottom: $grid-gutter-width*1.5;
  figure {
    background: $brand-secondary url(../images/logo-trans.png) no-repeat center center;
    background-size: 100px auto;
    margin: 0;
  }
  h2 {
    font-size: $font-size-h3;
    margin: .7em 0 .9em;
    a {
      color: $white;
      text-decoration: none;
    }
  }
}
