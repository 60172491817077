/*! Pushy - v1.1.0 - 2017-1-30
* Pushy is a responsive off-canvas navigation menu using CSS transforms & transitions.
* https://github.com/christophery/pushy/
* by Christopher Yee */

$menu_width:    375px !default;
$menu_width_md: 450px !default;
$menu_width_lg: 580px !default;
$menu_width_xl: 680px !default;

/* Menu Appearance */

.pushy{
    position: fixed;
    width: $menu_width;
    @include media-breakpoint-up(md) {
      width: $menu_width_md;
    }
    @include media-breakpoint-up(lg) {
      width: $menu_width_lg;
    }
    @include media-breakpoint-up(xl) {
      width: $menu_width_xl;
    }
    height: 100%;
    top: 0;
    z-index: 9999;
    background: #191918;
    overflow: auto;
    -webkit-overflow-scrolling: touch; /* enables momentum scrolling in iOS overflow elements */

    a{
        display: block;
        color: #b3b3b1;
        padding: 15px 30px;
        text-decoration: none;
    }

    a:hover{
        color: #FFF;
    }

    ul:first-child{
        margin-top: 10px;
    }

    &.pushy-left{
        left: 0;
    }

    &.pushy-right{
        right: 0;
    }
}

.pushy-content{
    visibility: hidden;
}

/* Menu Movement */

.pushy-left{
    -webkit-transform: translate3d( unquote('-' + $menu_width) ,0,0);
    -ms-transform: translate3d( unquote('-' + $menu_width) ,0,0);
    transform: translate3d( unquote('-' + $menu_width) ,0,0);
    @include media-breakpoint-up(md) {
      -webkit-transform: translate3d( unquote('-' + $menu_width_md) ,0,0);
      -ms-transform: translate3d( unquote('-' + $menu_width_md) ,0,0);
      transform: translate3d( unquote('-' + $menu_width_md) ,0,0);
    }
    @include media-breakpoint-up(lg) {
      -webkit-transform: translate3d( unquote('-' + $menu_width_lg) ,0,0);
      -ms-transform: translate3d( unquote('-' + $menu_width_lg) ,0,0);
      transform: translate3d( unquote('-' + $menu_width_lg) ,0,0);
    }
    @include media-breakpoint-up(xl) {
      -webkit-transform: translate3d( unquote('-' + $menu_width_xl) ,0,0);
      -ms-transform: translate3d( unquote('-' + $menu_width_xl) ,0,0);
      transform: translate3d( unquote('-' + $menu_width_xl) ,0,0);
    }
}

.pushy-open-left{
    #container,
    .push{
        -webkit-transform: translate3d( $menu_width ,0,0);
        -ms-transform: translate3d( $menu_width ,0,0);
        transform: translate3d( $menu_width ,0,0);
        @include media-breakpoint-up(md) {
          -webkit-transform: translate3d( $menu_width_md ,0,0);
          -ms-transform: translate3d( $menu_width_md ,0,0);
          transform: translate3d( $menu_width_md ,0,0);
        }
        @include media-breakpoint-up(lg) {
          -webkit-transform: translate3d( $menu_width_lg ,0,0);
          -ms-transform: translate3d( $menu_width_lg ,0,0);
          transform: translate3d( $menu_width_lg ,0,0);
        }
        @include media-breakpoint-up(xl) {
          -webkit-transform: translate3d( $menu_width_xl ,0,0);
          -ms-transform: translate3d( $menu_width_xl ,0,0);
          transform: translate3d( $menu_width_xl ,0,0);
        }
    }
}

.pushy-right{
    -webkit-transform: translate3d( $menu_width ,0,0);
    -ms-transform: translate3d( $menu_width ,0,0);
    transform: translate3d( $menu_width ,0,0);
    @include media-breakpoint-up(md) {
      -webkit-transform: translate3d( $menu_width_md ,0,0);
      -ms-transform: translate3d( $menu_width_md ,0,0);
      transform: translate3d( $menu_width_md ,0,0);
    }
    @include media-breakpoint-up(lg) {
      -webkit-transform: translate3d( $menu_width_lg ,0,0);
      -ms-transform: translate3d( $menu_width_lg ,0,0);
      transform: translate3d( $menu_width_lg ,0,0);
    }
    @include media-breakpoint-up(xl) {
      -webkit-transform: translate3d( $menu_width_xl ,0,0);
      -ms-transform: translate3d( $menu_width_xl ,0,0);
      transform: translate3d( $menu_width_xl ,0,0);
    }
}

.pushy-open-right{
    #container,
    .push{
        -webkit-transform: translate3d( unquote('-' + $menu_width) ,0,0);
        -ms-transform: translate3d( unquote('-' + $menu_width) ,0,0);
        transform: translate3d( unquote('-' + $menu_width) ,0,0);
        @include media-breakpoint-up(md) {
          -webkit-transform: translate3d( unquote('-' + $menu_width_md) ,0,0);
          -ms-transform: translate3d( unquote('-' + $menu_width_md) ,0,0);
          transform: translate3d( unquote('-' + $menu_width_md) ,0,0);
        }
        @include media-breakpoint-up(lg) {
          -webkit-transform: translate3d( unquote('-' + $menu_width_lg) ,0,0);
          -ms-transform: translate3d( unquote('-' + $menu_width_lg) ,0,0);
          transform: translate3d( unquote('-' + $menu_width_lg) ,0,0);
        }
        @include media-breakpoint-up(xl) {
          -webkit-transform: translate3d( unquote('-' + $menu_width_xl) ,0,0);
          -ms-transform: translate3d( unquote('-' + $menu_width_xl) ,0,0);
          transform: translate3d( unquote('-' + $menu_width_xl) ,0,0);
        }
    }
}

.pushy-open-left,
.pushy-open-right{
    .pushy{
        -webkit-transform: translate3d(0,0,0);
        -ms-transform: translate3d(0,0,0);
        transform: translate3d(0,0,0);
    }

    .pushy-content{
        visibility: visible;
    }
}

/* Menu Transitions */

#container,
.pushy,
.push{
    transition: transform .2s cubic-bezier(.16, .68, .43, .99);
}

.pushy-content{
    transition: visibility .2s cubic-bezier(.16, .68, .43, .99);
}

/* Site Overlay */

.site-overlay{
    display: none;
}

.pushy-open-left,
.pushy-open-right{
    .site-overlay{
        display: block;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 9998;
        background-color: rgba(0,0,0,0.5);
        -webkit-animation: fade 500ms;
        animation: fade 500ms;
    }
}

@keyframes fade{
    0%   { opacity: 0; }
    100% { opacity: 1; }
}

@-webkit-keyframes fade{
    0%   { opacity: 0; }
    100% { opacity: 1; }
}

/* Submenu Appearance */

.pushy-submenu{
    ul{
        padding-left: 15px;
        transition: max-height 0.2s ease-in-out,
                    visibility 0.2s ease-in-out;

        .pushy-link{
            transition: opacity 0.2s ease-in-out;
        }
    }

    /* Submenu Buttons */

    button{
        width: 100%;
        color: #b3b3b1;
        padding: 15px 30px;
        text-align: left;
        background: transparent;
        border: 0;

        &:hover{
            color: #FFF;
        }
    }

    /* Submenu Icon */

    > a,
    > button {
        position: relative;
    }

    > a::after,
    > button::after {
        content: '';
        display: block;
        height: 11px;
        width: 8px;
        position: absolute;
        top: 50%;
        right: 15px;
        background: url("../img/arrow.svg") no-repeat;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        transition: transform 0.2s;
    }
}

/* Submenu Movement */

.pushy-submenu-closed{
    ul{
        max-height: 0;
        overflow: hidden;
        visibility: hidden;
    }

    .pushy-link{
        opacity: 0;
    }
}

.pushy-submenu-open{
    ul{
        max-height: 1000px;
        visibility: visible;
    }

    .pushy-link{
        opacity: 1;
    }

    /* Submenu Icon */

    a::after,
    button::after{
        -webkit-transform: translateY(-50%) rotate(90deg);
        -ms-transform: translateY(-50%) rotate(90deg);
        transform: translateY(-50%) rotate(90deg);
    }
}
