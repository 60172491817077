// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;
$spacer:                1rem;
$grid-gutter-width-base:30px;
$grid-gutter-width: $grid-gutter-width-base;
$grid-gutter-widths: (
  xs: $grid-gutter-width-base,
  sm: $grid-gutter-width-base,
  md: $grid-gutter-width-base,
  lg: $grid-gutter-width-base,
  xl: $grid-gutter-width-base
);
$border-width: 1px;
$border-radius: 5px;

//PUSHY menu
/*
$menu-width:    375px;
$menu-width-md: 520px;
$menu-width-lg: 620px;
$menu-width-xl: 720px;
*/


// Colors
$orange:        #F47D46;
$tan:           #BC8041;
$slate:         #5A6C66;
$white:         #F7F0DF;
$white-true:    #fff;
$black:         #232525;
$black-near:    #181A1A;
$black-true:    #000;
$gray:          #ABB2B1;
$gray22:        #222;
$gray33:        #333;
$gray3f:        #3F4343;

$brand-primary:         $orange;
$brand-secondary:       $slate;

$body-bg:               $gray33;
$body-color:            $white;

$link-color:            $brand-primary;
$link-decoration:       none;
$link-hover-color:      $brand-secondary;
$link-hover-decoration: underline;

// Fonts
$font-family-sans-serif: "Work Sans", "Helvetica Neue", Arial, sans-serif;
$font-family-serif:      "eurostile-extended", Georgia, "Times New Roman", Times, serif;
$font-family-base:       $font-family-sans-serif;

$font-weight-normal: 400;
$font-weight-base: $font-weight-normal;
$font-weight-med: 500;
$font-weight-bold: 900;

$line-height-base: 1.4;

$headings-margin-bottom: ($spacer / 2);
$headings-font-family:   $font-family-serif;
$headings-font-weight:   $font-weight-bold;
$headings-line-height:   1.1;
$headings-color:         inherit;

$font-size-base: 1rem;      // 18 / 16
$font-size-lg:   1.333rem;  // 24 / 22
$font-size-sm:   .888rem;   // 16 / 14
$font-size-xs:   .777rem;   // 14 / 12
$font-size-2xs:  .666rem;   // 12 / 11


$font-size-h1: 2.444rem;    // 44 / 40
$font-size-h2: 1.777rem;    // 32 / 28
$font-size-h3: 1.333rem;    // 24 / 22
$font-size-h4: 1.111rem;    // 20 / 18
$font-size-h5: 1rem;        // 18 / 16
$font-size-h6: .888rem;     // 16 / 14


// Forms

$input-padding-x:                15px;
$input-padding-y:                10px;
$input-line-height:              1;

$input-bg:                       $gray33;
$input-bg-disabled:              $gray;

$input-color:                    $white;
$input-border-color:             transparent;
$input-btn-border-width:         0px;
$input-box-shadow:               none;

$input-border-radius:            5px;
$input-border-radius-lg:         $input-border-radius;
$input-border-radius-sm:         $input-border-radius;

$input-bg-focus:                 $input-bg;
$input-border-focus:             transparent;
$input-box-shadow-focus:         none;
$input-color-focus:              $input-color;

$input-color-placeholder:        rgba($input-color,.8);

$input-padding-x-sm:             $input-padding-x;
$input-padding-y-sm:             $input-padding-y;

$input-padding-x-lg:             $input-padding-x;
$input-padding-y-lg:             $input-padding-y;

$input-height:                   50px;
$input-height-lg:                $input-height;
$input-height-sm:                $input-height;

// Buttons

$btn-padding-x:                  20px;
$btn-padding-y:                  10px;
$btn-line-height:                $input-line-height;
$btn-font-weight:                $font-weight-bold;
$btn-box-shadow:                 none;
$btn-focus-box-shadow:           none;
$btn-active-box-shadow:          none;

$btn-primary-color:              $white;
$btn-primary-bg:                 $brand-primary;
$btn-primary-border:             transparent;

$btn-secondary-color:            $white;
$btn-secondary-bg:               $slate;
$btn-secondary-border:           transparent;

$btn-default-color:              $slate;
$btn-default-bg:                 $white;
$btn-default-border:             transparent;

// Popovers

$popover-inner-padding:               0;
$popover-bg:                          $black;
$popover-max-width:                   400px;
$popover-border-width:                0;
$popover-border-color:                transparent;
$popover-box-shadow:                  none;

$popover-title-bg:                    darken($popover-bg, 3%);
$popover-title-padding-x:             0;
$popover-title-padding-y:             0;

$popover-content-padding-x:           0;
$popover-content-padding-y:           0;

$popover-arrow-width:                 15px;
$popover-arrow-color:                 $popover-bg;

$popover-arrow-outer-width:           ($popover-arrow-width + 1px);
$popover-arrow-outer-color:           fade-in($popover-border-color, .05);

