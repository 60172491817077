html {
  font-size: 14px;
  @include media-breakpoint-up(md) {
    font-size: 15px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 16px;
  }
  body {
    counter-reset: section;
    letter-spacing: .01rem;
  }
}
img {
  @extend .img-fluid;
}
