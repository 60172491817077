.blog,.block-posts {
  article.post {
    font-size: $font-size-sm;
    margin-bottom: $grid-gutter-width*1.75;
    text-align: center;
    p {
      margin-bottom: 1rem;
    }
    header {
      position: relative;
      figure {
        background-color: $gray22;
        margin: 0 0 $grid-gutter-width*.66;
        min-height: 180px;
        img {
          opacity: .66;
          width: 100%;
        }
      }
      div {
        position: absolute;
        width: 100%;
        padding: 0 $grid-gutter-width/3;
        text-align: center;
        left: 0;
        top: 40%;
        h2 {
          @extend h4;
          @extend .outlined-fill;
          a {
            text-decoration: none;
            color: $white;
          }
        }
        time {
          @extend .eurostile;
          font-weight: $font-weight-med;
          display: block;
          margin: $grid-gutter-width/2 0 0;
        }
      }
      &:hover {
        img {
          opacity: .9;
        }
      }
    }
  }
}
.block-posts {
  article.post {
    header {
/*
      figure {
        background-color: $gray22;
      }
*/
      div {
        h2 {
          font-size: $font-size-h3;
          @supports
            ((-webkit-text-stroke-color: $white)
            and
            (-webkit-text-fill-color: transparent))
            or
            ((-moz-text-stroke-color: $white)
            and
            (-moz-text-fill-color: transparent)) {
              -moz-text-fill-color: transparent;
              -webkit-text-fill-color: transparent;
              -moz-text-stroke-color: $white;
              -webkit-text-stroke-color: $white;
              -moz-text-stroke-width: $border-width;
              -webkit-text-stroke-width: $border-width;
            }
        }
        time {
          margin-top: $grid-gutter-width*2;
        }
      }
    }
  }
}
.blog {
  article.post {
    @extend .col-12;
    @extend .col-md-6;
    @extend .col-lg-4;
    &:nth-child(1) {
      @extend .col-md-12;
      @extend .col-lg-6;
      @include media-breakpoint-up(md) {
        header {
          div {
            h2 {
              font-size: $font-size-h3;
              @supports
                ((-webkit-text-stroke-color: $white)
                and
                (-webkit-text-fill-color: transparent))
                or
                ((-moz-text-stroke-color: $white)
                and
                (-moz-text-fill-color: transparent)) {
                  -moz-text-fill-color: transparent;
                  -webkit-text-fill-color: transparent;
                  -moz-text-stroke-color: $white;
                  -webkit-text-stroke-color: $white;
                  -moz-text-stroke-width: $border-width;
                  -webkit-text-stroke-width: $border-width;
                }
            }
            time {
              margin-top: $grid-gutter-width*2;
            }
          }
        }
      }
    }
    &:nth-child(2) {
      @extend .col-lg-6;
      @include media-breakpoint-up(lg) {
        header {
          div {
            h2 {
              font-size: $font-size-h3;
              @supports
                ((-webkit-text-stroke-color: $white)
                and
                (-webkit-text-fill-color: transparent))
                or
                ((-moz-text-stroke-color: $white)
                and
                (-moz-text-fill-color: transparent)) {
                  -moz-text-fill-color: transparent;
                  -webkit-text-fill-color: transparent;
                  -moz-text-stroke-color: $white;
                  -webkit-text-stroke-color: $white;
                  -moz-text-stroke-width: $border-width;
                  -webkit-text-stroke-width: $border-width;
                }
            }
            time {
              margin-top: $grid-gutter-width*2;
            }
          }
        }
      }
    }
  }
}

.single-post {
  .content {
    main {
      text-align: left;
      @include media-breakpoint-only(md) {
        flex: 0 0 83.444%;
        max-width: 83.444%;
      }
      @include media-breakpoint-down(sm) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
}
.tank-gallery {
  @extend .row;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: $grid-gutter-width*2;
  justify-content: center;
  div {
    @extend .col-4;
    @extend .col-md-3;
    @extend .col-lg-2;
    padding-left: 0;
    padding-right: 0;
  }
}
.posts-navigation {
  width: 100%;
  padding: 0 $grid-gutter-width/2;
  margin-bottom: $grid-gutter-width*2;
  text-align: center;
  .nav-links {
    .nav-previous,.nav-next,.nav-back-list {
      display: inline-block;
      padding: 0 $grid-gutter-width/3;
      a {
        @extend .btn;
        @extend .btn-secondary;
        @extend .btn-lg;
        padding: $grid-gutter-width/3 $grid-gutter-width*.4;
        min-width: 0;
      }
    }
  }
}
