.banner {
  background-color: $black;
  .identity {
    padding-left: 0;
    h1 {
      @extend .outlined-fill;
      font-size: $font-size-h5;
      display: none;
      @include media-breakpoint-up(md) {
        display: inline-block;
        margin-left: $grid-gutter-width;
      }
      @include media-breakpoint-up(lg) {
        margin-left: $grid-gutter-width*1.5;
      }
    }
    .brand {
      display: inline-block;
      width: 100%;
      max-width: 240px;
      background-color: $brand-primary;
      text-align: right;
      padding: $grid-gutter-width/3;
      img {
        width: 80px;
      }
      @include media-breakpoint-up(md) {
        max-width: 210px;
      }
      @include media-breakpoint-up(lg) {
        max-width: 240px;
      }
    }
  }
  #menu-primary-navigation {
    margin: 0;
    padding: 0;
    li.menu-item {
      padding: 0 0 $grid-gutter-width $grid-gutter-width;
      a {
        @extend h2;
        @extend .outlined;
        padding: 0;
        margin: 0;
        &:hover {
          @extend .outlined-hover;
        }
      }
      &.active {
        background: url(../images/bullet-orange.png) no-repeat 0 12px;
        background-size: 12px;
        a {
          @extend .outlined-fill;
        }
      }
      &:after {
        @extend .eurostile;
        font-weight: $font-weight-base;
        font-size: $font-size-2xs;
        counter-increment: section;
        content: "0" counter(section);
      }
    }
  }
  .pushy {
    background-color: $black;
    z-index: 100;
    transition: transform .3s cubic-bezier(.16,.68,.43,.99);
    padding: $grid-gutter-width $grid-gutter-width*.66;
    @include media-breakpoint-up(md) {
      padding: $grid-gutter-width $grid-gutter-width $grid-gutter-width $grid-gutter-width*1.5;
    }
    .logged-in.admin-bar & {
      top: 46px;
      @media screen and (min-width: 783px) {
        top: 32px;
      }
    }
  }
  .menu-btn {
    z-index: 101;
    position: absolute;
    right: $grid-gutter-width;
    top: $grid-gutter-width*.66;;
    background-color: $brand-secondary;
    width: $grid-gutter-width*2;
    height: $grid-gutter-width*2;
    padding-left: 16px;
    padding-right: 16px;
    border: none;
    border-radius: $border-radius;
    cursor: pointer;
    &:hover {
      background-color: rgba($brand-secondary,.9);
    }
    &:focus {
      outline: none;
    }
    .icon-bar {
      display: block;
      width: 28px;
      height: 5px;
      border-radius: 1px;
      background-color: $white;
      transition: all 0.3s;
      &+.icon-bar {
        margin-top: 5px;
      }
    }
    .pushy-open-right & {
      .icon-bar {
        border-radius: 2px;
        height: 7px;
        width: 25px;
        margin-left: 9px;
        &.top-bar {
          transform: rotate(45deg);
          transform-origin: -22% 10%;
        }
        &.mid-bar {
          opacity: 0;
        }
        &.btn-bar {
          transform: rotate(-45deg);
          transform-origin: -22% 90%;
        }
      }
    }
  }
}
