.content {
  main {
    @extend .col-md-10;
    @extend .offset-md-1;
    @extend .col-lg-8;
    @extend .offset-lg-2;
    @extend .block-padding;
    text-align: center;
  }
}
.block {
  padding: $grid-gutter-width 0 $grid-gutter-width*2;

  &.block-home {
    padding: 0;
    background-color: $black-near;
    main {
      @extend .block-padding-md;
      h1 {
        margin: 0 0 $grid-gutter-width*2;
      }
    }
  }
  &.block-newsletter {
    background-color: $black-near;
    padding: $grid-gutter-width*1.5 0;
    h2 {
      margin: 0 0 $grid-gutter-width;
      text-align: center;
    }
    #mc_embed_signup {
      max-width: 400px;
      margin: 0 auto;
      input {
        height: $input-height*.8;
      }
      .btn {
        min-width: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        height: $input-height*.8;
      }
    }
  }


  &.block-posts {
    @extend .block-padding-md;
    background-color: $black;
    h1 {
      margin: 0 0 $grid-gutter-width*2;
      text-align: center;
    }
  }
  &.block-contact {
    background-color: $brand-secondary;
    padding: $grid-gutter-width*2 0;
    text-align: center;
    h1 {
      margin: 0 0 $grid-gutter-width*1.5;
    }
    p:last-child {
      margin: $grid-gutter-width 0 0;
    }
    .btn {
      min-width: 300px;
      @include media-breakpoint-up(lg) {
        min-width: 400px;
      }
    }
  }

  &.block-listen {
    .listen {
      font-size: $font-size-xs;
      margin-bottom: $grid-gutter-width*1.5;
      figure {
        background: $brand-secondary url(../images/logo-trans.png) no-repeat center center;
        background-size: 100px auto;
        margin: 0;
      }
      h2 {
        font-size: $font-size-h3;
        margin: .7em 0 .9em;
      }
      p {
        margin-bottom: 1rem;
      }
    }
  }

  &.block-equipment {
    @extend .block-padding;
    .row {
//       justify-content: center;
    }
    .equipment-type-block {
      margin-bottom: $grid-gutter-width*2;
      h2 {
        text-align: center;
        margin-bottom: $grid-gutter-width;
      }
    }




    .equipment-list {
      font-size: $font-size-sm;
      line-height: 1.2;
      margin-bottom: $grid-gutter-width*2;
      h3 {
        margin-bottom: 1.5em;
        font-size: $font-size-h4;
      }
      p {
        margin-bottom: $grid-gutter-width/3;
        a {
          color: $white;
          text-decoration: none;
          padding-bottom: 2px;
          border-bottom: 1px dotted rgba($white,.4);
          &:hover {
            border-bottom: 1px solid rgba($white,.6);
          }
        }
      }
      .single-list {
        @include media-breakpoint-up(lg) {
          columns: 3 auto;
          column-gap: $grid-gutter-width;
        }
      }
    }
  }

  &.block-clients {
    @extend .block-padding;
    .client-list {
      font-size: $font-size-sm;
      line-height: 1.2;
      columns: 2 auto;
      p {
        margin-bottom: $grid-gutter-width/3;
        a {
          color: $white;
          text-decoration: none;
          padding-bottom: 2px;
          border-bottom: 1px dotted rgba($white,.4);
          &:hover {
            border-bottom: 1px solid rgba($white,.6);
          }
        }
      }
      @include media-breakpoint-up(md) {
        columns: 3 auto;
      }
      @include media-breakpoint-up(xl) {
        columns: 4 auto;
      }
    }
    .client-list-after {
      text-align: center;
      margin-top: $grid-gutter-width*1.5;
      @include media-breakpoint-up(lg) {
        margin-top: $grid-gutter-width*2;
      }
    }
  }

  &.block-engineers {
    .engineer {
      margin-bottom: $grid-gutter-width*1.5;
      .subhead {
        margin-bottom: 0;
        font-size: $font-size-sm;
      }
      figure {
        @include media-breakpoint-up(md) {
          margin-bottom: $grid-gutter-width*1.5;
        }
      }
      h2 {
        font-size: $font-size-h3;
        margin-bottom: 1em;
        @include media-breakpoint-up(md) {
          margin-bottom: $grid-gutter-width;
        }
      }
    }
  }

  &.block-booking {
    background-color: $brand-secondary;
    padding: $grid-gutter-width*2 0;
  }

}
.slideshow {
  position: relative;
  background-color: $gray22;
  .slides {
    visibility: hidden;
    &.slick-initialized {
      visibility: visible;
    }
    .slick-slide {
      img {
        width: 100%;
      }
    }
  }
  .btn {
    position: absolute;
    bottom: 20%;
    left: 50%;
    transform: translateX(-50%);
    min-width: 300px;
    @include media-breakpoint-up(lg) {
      min-width: 340px;
    }
  }
}
