// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}
.form-control {
  font: $font-size-base $font-family-serif;
  height: $input-height;
  &:focus {
		&::-webkit-input-placeholder {
		  color: transparent;
		}
		&:-moz-placeholder { /* Firefox 18- */
		  color: transparent;
		}
		&::-moz-placeholder {  /* Firefox 19+ */
		  color: transparent;
		}
		&:-ms-input-placeholder {
		  color: transparent;
		}
	}
}
.block-booking {
  .gform_wrapper {
    h3.gform_title {
      font-size: $font-size-h2;
      text-align: center;
      margin: 0 0 $grid-gutter-width*2;
    }
    .gform_body {
      .gfield {
        padding: 0;
        margin: 0 0 20px;
        label.gfield_label {
          @extend .sr-only;
        }
        .ginput_container {
          margin: 0;
          input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
            @extend .form-control;
            padding: $input-padding-y $input-padding-x;
          }
          textarea {
            @extend .form-control;
            height: 190px;
          }
        }
      }
    }
    .gform_footer,.gform_page_footer {
      text-align: center;
      margin: 0;
      input.button,input[type=submit] {
        @extend .btn;
        @extend .btn-default;
        margin: 0;
      }
    }
    div.validation_error {
      @extend .eurostile;
      font-weight: $font-weight-base;
      background-color: $brand-primary;
      color: $white;
      padding: $grid-gutter-width;
      margin: 0 0 $grid-gutter-width;
      border: none;
      border-radius: $input-border-radius;
      clear: both;
      width: 100%;
      text-align: center;
    }
    &.gform_validation_error {
      .gform_body ul li.gfield.gfield_error:not(.gf_left_half):not(.gf_right_half) {
        max-width: none !important;
        border: none !important;
        background-color: transparent !important;
        margin: 0 0 20px !important;
        .ginput_container {
          margin: 0 !important;
          input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]),
          select, textarea {
            background-color: $black-true;
          }
        }
        .validation_message {
          display: none !important;
        }
      }
    }
  }
  .gform_confirmation_wrapper {
    @extend .eurostile;
    @extend h4;
    background-color: $black;
    color: $white;
    padding: $grid-gutter-width*1.5;
    line-height: 1.5;
    margin: 0;
    border: none;
    border-radius: $input-border-radius;
    clear: both;
    width: 100%;
    text-align: center;
  }
}
