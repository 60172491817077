footer.footer {
  background-color: $gray3f;
  @extend .eurostile;
  font-size: $font-size-2xs;
  .footer-nav {
    background-color: $brand-primary;
    padding: $grid-gutter-width/3.5 0;
    .nav {
      justify-content: center;
      .menu-item {
        margin: 0 $grid-gutter-width/2;
        a {
          text-decoration: none;
          color: $white;
          line-height: 1;
          &:hover {
            color: $black;
          }
        }
      }
      @include media-breakpoint-down(sm) {
        flex-direction: column;
        .menu-item {
          text-align: center;
          a {
            display: block;
            padding: 5px 0;
          }
        }
      }
    }
  }


  .content-info {
    padding-top: $grid-gutter-width*1.5;
    padding-bottom: $grid-gutter-width*1.5;
    font-weight: $font-weight-base;
    text-align: center;
    .logo {
      margin-bottom: $grid-gutter-width*1.5;
      img {
        height: 120px;
      }
    }
    p:last-child {
      margin-bottom: 0;
    }
  }
}
