.btn {
  @extend .eurostile;
  font-size: $font-size-xs;
  min-width: 240px;
  border-width: none !important;
  &:hover {
    cursor: pointer;
    color: $black;
  }
  outline-style:none;
  box-shadow:none;
  border-color:transparent;
  &.btn-primary {
    &:hover {
      background-color: $white;
      border-color: $white;
    }
  }
  &.btn-secondary {
    &:hover {
      color: $white;
      background-color: rgba($btn-secondary-bg,.9);
      border-color: rgba($btn-secondary-bg,.9);
    }
  }
  &.btn-default {
    background-color: $btn-default-bg;
    color: $btn-default-color;
    &:hover {
      background-color: $orange;
      border-color: $orange;
      color: $white;
    }
  }
  &.btn-lg {
    font-size: $font-size-base;
    padding: 12px 30px;
  }
}
